import "./home.css";
import { useState } from "react";
import Video from "../../components/video/Video";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

function Home() {
  const [isFilled, setIsFilled] = useState(false);

  const handleInputChange = (event) => {
    setIsFilled(event.target.value !== "");
  };

  return (
    <div className="homeParent">
      <div className="homeTop">
        <Video />
      </div>
      <div className="homeMiddle"></div>

      <footer className="homeFooter">
        <div className="homeBottom">
          <div className="homeBottomContent">
            <h2 className="subscribe">Follow us on social for updates.</h2>
            <div className="homeBottomContentIconsBox">
              <a
                href="https://www.instagram.com/legaleaseindia/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                target="_blank"
              >
                <FaInstagram className="homeBottomContentIcons" />
              </a>
              <a
                href="https://twitter.com/LegalEaseIndia?t=V2Ad8p5yHeArTvSsDbCMZw&s=08"
                target="_blank"
              >
                <FaXTwitter className="homeBottomContentIcons" />
              </a>
              <a href="" target="_blank">
                <FaLinkedin className="homeBottomContentIcons" />
              </a>
            </div>
            <h2 className="subscribe">Subscribe</h2>
            <p className="signUpText">
              Sign up to be the first to know about our soft launch events.
            </p>
            <div className="homeSubscribe">
              <div className="homeSubscribeInput">
                <input
                  id="homeInput"
                  type="email"
                  className="homeSignUpInput"
                  onChange={handleInputChange}
                />
                <label
                  htmlFor="homeInput"
                  className={`homeInputLabel ${isFilled ? "filled" : ""}`}
                >
                  Email
                </label>
              </div>
              <button className="homeSignUpBtn">Sign Up</button>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright © 2023 LegalEase - All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}
export default Home;
