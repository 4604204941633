import React from "react";
import "./video.css";

function Video() {
  return (
    <div className="video-container">
      {/* <div className="overlay"></div> */}
      <div className="video-text-top">
        <p>LEGALEASE</p>
      </div>

      <video
        src="https://legalease.s3.ap-south-1.amazonaws.com/website/background-video/LegalEase.mp4"
        autoPlay
        loop
        muted
        playsInline
        className="video-element"
      ></video>
      <div className="video-text-middle">
        <p>Launching Soon</p>
      </div>
    </div>
  );
}

export default Video;
